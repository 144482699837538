import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';

import ProfessionalsSVG from '../../../static/svgs/employee.inline.svg';
import AchieversSVG from '../../../static/svgs/success.inline.svg';
import ValueCentricSVG from '../../../static/svgs/mindset.inline.svg';

const StyledDivWrapper = styled.div`
  > div {
    padding: 10% 0px;
  }

  .card-column {
    background-color: ${props => props.theme.coldBluePalette.original};
    padding: 40px 20px;
    height: 100%;
    border-radius: 10px;
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.2);

    p {
      margin-bottom: 0px;
    }
  }
`;

function WhatWeLookForComponent() {
  return (
    <StyledDivWrapper>
      <div>
        <Row type="flex" justify="center">
          <Col xs={22} sm={22} md={22} lg={18} xl={16} xxl={14}>
            <div style={{ textAlign: 'center', maxWidth: 600, margin: 'auto' }}>
              <h2>WHAT WE LOOK FOR</h2>
            </div>
            <br />
            <Row gutter={[20, 20]} justify="center">
              <Col xs={23} sm={24} md={8} lg={8} xl={8}>
                <div className="card-column">
                  <ProfessionalsSVG height="4em" width="4em" />
                  <h4 style={{ marginTop: 20 }}>Experienced Professionals</h4>
                  <p>
                    We are looking forward to know professionals with working
                    experience who are in plans of creating a new career path or
                    simply furthering their career. They are driven to achieve
                    bigger successes, share their knowledge, mentor future
                    experts, and lead our high-caliber teams.
                  </p>
                </div>
              </Col>
              <Col xs={23} sm={24} md={8} lg={8} xl={8}>
                <div className="card-column">
                  <AchieversSVG height="4em" width="4em" />

                  <h4 style={{ marginTop: 20 }}>Young Achievers</h4>
                  <p>
                    PRIME is looking for young, dynamic people who have an
                    inherent drive to succeed even at an early age. Their age
                    does not stop them from striving for ambitious goals, taking
                    opportunities as they come, and ultimately, working toward
                    climbing the corporate ladder.
                  </p>
                </div>
              </Col>
              <Col xs={23} sm={24} md={8} lg={8} xl={8}>
                <div className="card-column">
                  <ValueCentricSVG height="4em" width="4em" />
                  <h4 style={{ marginTop: 20 }}>Value-centric Individuals</h4>
                  <p>
                    We want to meet individuals who embody the core values. We
                    always strive to give clients the best service experience
                    and this is made possible through our team of experts whose
                    values are strongly founded.
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </StyledDivWrapper>
  );
}

export default WhatWeLookForComponent;
