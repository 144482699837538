import React, { useState } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { Row, Col } from 'antd';

// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const StyledDivWrapper = styled.div`
  position: relative;
  > div {
    padding: 10% 0px;
    background: rgba(20, 20, 20, 0.6);
  }

  .carousel-card-container {
    background-color: white;
    box-shadow: 0px 7px 7px 0px rgba(0, 0, 0, 0.22);
  }

  p {
    margin-bottom: 0px;
  }

  .slick-dots {
    position: unset;
    text-align: left;
  }

  .slick-dots li button:before {
    font-size: 12px;
  }

  .slick-dots li {
    margin: 0;
  }

  .slick-dots li.slick-active button:before {
    color: ${props => props.theme.bluePalette.dark};
  }

  ::after {
    height: 100%;
    width: 100%;
    background-image: ${props => `url('${props.imgSrc}')`};
    background-size: cover;
    background-position: center;
    /* filter: blur(3px); */
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    filter: blur(2px);
  }
`;

function OurCoreValuesComponent() {
  const [coreValueIndex, setCoreValueIndex] = useState(0);

  const coreValues = [
    {
      name: 'Productivity',
      description: `Being in a fast-paced industry, it is instilled in our culture that we put importance on time and focus on the outputs that we can produce. Hence, PRIME made sure to create an environment that is conducive to reaching optimal work productivity.`,
      imageSrc: `../../images/careers/bg-productivity.jpg`,
    },
    {
      name: 'Responsiveness',
      description: `We believe that every passing second of not responding to a potential client is a wasted opportunity. In PRIME, we view responsiveness as a sign of respect to not only our clients, but to our colleagues as well.`,
      imageSrc: `../../images/careers/bg-responsiveness.jpg`,
    },
    {
      name: 'Integrity',
      description: `Integrity is one of the core values which we embody in the form our work quality and our people.  Honesty and having strong moral principles are important to us, as we believe that building trust is essential in building a better company.`,
      imageSrc: `../../images/careers/bg-integrity.jpg`,
    },
    {
      name: 'Mastery',
      description: `We are proud to say that our environment fosters opportunities for continuous development. This way, our people are able to hone their skills which allows them to become the best versions of themselves, both personally and professionally.`,
      imageSrc: `../../images/careers/bg-mastery.jpg`,
    },
    {
      name: 'Excellence',
      description: `We challenge traditional ideas through constant innovation. Thus, we are able to provide services with high standards and offer quality customized-solutions to our clients.`,
      imageSrc: `../../images/careers/bg-excellence.jpg`,
    },
    {
      name: 'Commitment',
      description: `In PRIME, we are dedicated in developing positive relationships with our clients. We are also committed to providing opportunities in order to boost employee morale and to support career growth for their long-term success.`,
      imageSrc: `../../images/careers/bg-commitment.jpg`,
    },
  ];

  const settings = {
    initialSlide: coreValueIndex,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    afterChange: index => {
      setCoreValueIndex(index);
    },
  };

  return (
    <StyledDivWrapper imgSrc={coreValues[coreValueIndex].imageSrc}>
      <div>
        <Row type="flex" justify="center">
          <Col xs={22} sm={22} md={22} lg={16}>
            <div className="carousel-card-container">
              <div>
                <Row type="flex">
                  <Col xs={24} sm={24} md={16} lg={16}>
                    <div style={{ padding: '10%' }}>
                      <h5>OUR CORE VALUES</h5>
                      <Slider {...settings}>
                        {coreValues.map(value => (
                          <div key={value.name} style={{ padding: 40 }}>
                            <h2>{value.name}</h2>
                            <p>{value.description}</p>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </Col>
                  <Col
                    xs={0}
                    sm={0}
                    md={8}
                    lg={8}
                    style={{
                      backgroundImage: `url(${coreValues[coreValueIndex].imageSrc})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                  />
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </StyledDivWrapper>
  );
}

export default OurCoreValuesComponent;
