import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { Tabs, Row, Col } from 'antd';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

const { TabPane } = Tabs;

const StyledDivWrapper = styled.div`
  padding: 5% 0px;
`;

const StyledPostingItem = styled.div`
  position: relative;
  height: 175px;
  background-image: ${props => `url('${props.thumbnail}')`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 10px;

  > div {
    position: relative;
    z-index: 1;
    color: white;
  }

  ::after {
    content: '';
    top: 0%;
    left: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 0;
    background-color: rgba(10, 10, 10, 0.5);
  }
`;

function JobPostingsComponent() {
  const data = useStaticQuery(graphql`
    {
      allStrapiCareerPostings {
        nodes {
          strapiId
          id
          thumbnail
          title
          posting_type
          strapiId
          url_link
          visibility
          experience
        }
      }
    }
  `);
  return (
    <StyledDivWrapper>
      <Row type="flex" justify="center">
        <Col xs={22} sm={22} md={20} lg={16} xl={18} xxl={14}>
          <Row>
            <Col xs={24} sm={24} md={20} lg={16} xl={16} xxl={14}>
              <div style={{ fontSize: '1.5em', marginBottom: 5 }}>
                Do what you love and give out the passion in you while growing
                your career with <b>PRIME Philippines</b>.
              </div>
            </Col>
          </Row>

          <Tabs defaultActiveKey="1">
            <TabPane tab="JOB POSTINGS" key="1">
              <Row type="flex" gutter={[12, 12]}>
                {data.allStrapiCareerPostings.nodes
                  .filter(posting => posting.visibility === true)
                  .filter(posting => posting.posting_type === true)
                  .map(posting => (
                    <Col xs={12} sm={12} md={8} lg={6} key={posting.id}>
                      <OutboundLink
                        href={posting.url_link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <StyledPostingItem thumbnail={posting.thumbnail}>
                          <div>
                            <div>
                              <small>{posting.experience}</small>
                            </div>
                            <div>
                              <b>{posting.title}</b>
                            </div>
                          </div>
                        </StyledPostingItem>
                      </OutboundLink>
                    </Col>
                  ))}
              </Row>
            </TabPane>
            <TabPane tab="INTERNSHIPS" key="2">
              <Row type="flex" gutter={[12, 12]}>
                {data.allStrapiCareerPostings.nodes
                  .filter(posting => posting.visibility === true)
                  .filter(posting => posting.posting_type === false)
                  .map(posting => (
                    <Col xs={12} sm={12} md={8} lg={6} key={posting.strapiId}>
                      <OutboundLink
                        href={posting.url_link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <StyledPostingItem thumbnail={posting.thumbnail}>
                          <div>
                            <div>
                              <small>{posting.experience}</small>
                            </div>
                            <div>
                              <b>{posting.title}</b>
                            </div>
                          </div>
                        </StyledPostingItem>
                      </OutboundLink>
                    </Col>
                  ))}
              </Row>
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </StyledDivWrapper>
  );
}

export default JobPostingsComponent;
