import React from 'react';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';

import WhyWorkWithUsHeader from '../components/careers-page/WhyWorkWithUsHeader';
import OurCoreValuesComponent from '../components/careers-page/OurCoreValuesComponent';
import WhatWeLookForComponent from '../components/careers-page/WhatWeLookForComponent';
import RoadMapToSuccessComponent from '../components/careers-page/RoadMapToSuccessComponent';
import JobPostingsComponent from '../components/careers-page/JobPostingsComponent';

const StyledDivWrapper = styled.div`
  padding-top: 89px;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
`;

function CareersPage({ location }) {
  return (
    <Layout headerstyle="default" source="Careers Page">
      <SEO
        title="Careers"
        description="The Leading Commercial Real Estate Consultancy Firm in the Philippines."
        meta={[
          {
            property: 'og:url',
            content: location.href,
          },
          {
            name: 'keywords',
            content:
              'Real Estate Service Provider in PH,Real Estate Service in the Philippines,Real Estate Service Firm in PH,Real Estate Service Firm in the Philippines',
          },
        ]}
      />
      <StyledDivWrapper>
        <WhyWorkWithUsHeader />
        <OurCoreValuesComponent />
        <WhatWeLookForComponent />
        <RoadMapToSuccessComponent />
        <JobPostingsComponent />
      </StyledDivWrapper>
    </Layout>
  );
}

export default CareersPage;
