import React from 'react';
import styled from 'styled-components';

import { Row, Col, Steps } from 'antd';
import { useState } from 'react';

const { Step } = Steps;

const StyledDivWrapper = styled.div`
  background-color: ${props => props.theme.primeBluePalette.original};
  h2 {
    color: ${props => props.theme.primeYellowPalette.original};
  }

  .email-careers {
    color: ${props => props.theme.primeYellowPalette.original};
    text-decoration: underline;
  }

  p,
  h3 {
    color: ${props => props.theme.whitePalette.original};
  }

  .bg-background {
    background-image: url('../../images/careers/roadmap-bg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: ${props => props.theme.whitePalette.original};
  }
  .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    color: ${props => props.theme.primeBluePalette.original};
  }

  .ant-steps-item-process .ant-steps-item-icon {
    background-color: ${props => props.theme.whitePalette.original};
  }

  .ant-steps-item-wait .ant-steps-item-icon {
    background-color: transparent;
  }

  .ant-steps-item-process .ant-steps-item-icon {
    border-color: ${props => props.theme.whitePalette.original};
  }

  .ant-steps-item-wait .ant-steps-item-icon {
    border-color: ${props => props.theme.whitePalette.original};
  }

  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: ${props => props.theme.whitePalette.original};
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #f0f0f0;
  }

  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: ${props => props.theme.whitePalette.original};
  }
`;

function RoadMapToSuccessComponent() {
  const [currentSteps, setCurrentSteps] = useState(0);

  function RoadmapSteps() {
    return (
      <div>
        {currentSteps === 0 && (
          <div>
            <Row>
              <Col xs={0} sm={0} md={24} lg={24}>
                <h3>Application</h3>
              </Col>
            </Row>
            <p>
              You may send your application through our website or send it
              directly to{' '}
              <a
                className="email-careers"
                href="mailto:careers@primephilippines.com"
              >
                careers@primephilippines.com
              </a>
              . Paper screening is part of the hiring process. You will be
              notified once you qualified any of our openings through personal
              calls or email.
            </p>
          </div>
        )}
        {currentSteps === 1 && (
          <div>
            <Row>
              <Col xs={0} sm={0} md={24} lg={24}>
                <h3>Initial Screening</h3>
              </Col>
            </Row>
            <p>
              Upon reviewing your application, an initial interview with the
              Recruitment Officer and the Immediate Supervisor will be
              conducted. Your interview day is primarily focused on getting to
              know you better and giving you an idea about how PRIME engage as
              an employer.
            </p>
          </div>
        )}
        {currentSteps === 2 && (
          <div>
            <Row>
              <Col xs={0} sm={0} md={24} lg={24}>
                <h3>Initial Assessment</h3>
              </Col>
            </Row>
            <p>
              To ensure the quality of talents, PRIME believes in using
              comprehensive testing tools to help reveal your key competencies,
              potentials, skills and preferences which may not be seen during
              your interview.
            </p>
          </div>
        )}

        {currentSteps === 3 && (
          <div>
            <Row>
              <Col xs={0} sm={0} md={24} lg={24}>
                <h3>Group Case Study Analysis</h3>
              </Col>
            </Row>
            <p>
              Upon completing the initial assessment, a more in-depth discussion
              will be held internally. Group analysis will focus on your
              personal ability and it is your time to showcase your knowledge
              and skills on your expertise.
            </p>
          </div>
        )}

        {currentSteps === 4 && (
          <div>
            <Row>
              <Col xs={0} sm={0} md={24} lg={24}>
                <h3>Hiring and Placement</h3>
              </Col>
            </Row>
            <p>
              For final decision of your application, interview will be
              conducted by the Managing Director to further ensure your
              suitability and finalize your technical competence to the desired
              role.
            </p>
          </div>
        )}
      </div>
    );
  }

  return (
    <StyledDivWrapper>
      <Row>
        <Col
          xs={0}
          sm={0}
          md={6}
          lg={7}
          xl={9}
          xxl={10}
          className="bg-background"
        />
        <Col xs={24} sm={24} md={18} lg={17} xl={15} xxl={14}>
          <div style={{ padding: '5%' }}>
            <h2>ROADMAP TO SUCCESS</h2>
            <p>
              Follow the steps to be part of our young and energetic team of professionals
              that help grows the business and your career.
            </p>

            <br />

            <Row>
              <Col xs={0} sm={24} md={24} lg={24}>
                <Steps
                  current={currentSteps}
                  onChange={stepIndex => {
                    setCurrentSteps(stepIndex);
                  }}
                  size="small"
                >
                  <Step status={currentSteps === 0 ? 'process' : 'wait'} />
                  <Step status={currentSteps === 1 ? 'process' : 'wait'} />
                  <Step status={currentSteps === 2 ? 'process' : 'wait'} />
                  <Step status={currentSteps === 3 ? 'process' : 'wait'} />
                  <Step status={currentSteps === 4 ? 'process' : 'wait'} />
                </Steps>
              </Col>
            </Row>

            <Row>
              <Col xs={24} sm={0} md={0} lg={0}>
                <Steps
                  current={currentSteps}
                  onChange={stepIndex => {
                    setCurrentSteps(stepIndex);
                  }}
                  size="small"
                >
                  <Step
                    title="Application"
                    status={currentSteps === 0 ? 'process' : 'wait'}
                  />
                  <Step
                    title="Initial Screening"
                    status={currentSteps === 1 ? 'process' : 'wait'}
                  />
                  <Step
                    title="Initial Assessment"
                    status={currentSteps === 2 ? 'process' : 'wait'}
                  />
                  <Step
                    title="Group Case Study Analysis"
                    status={currentSteps === 3 ? 'process' : 'wait'}
                  />
                  <Step
                    title="Hiring and Placement"
                    status={currentSteps === 4 ? 'process' : 'wait'}
                  />
                </Steps>
              </Col>
            </Row>

            <Row>
              <Col xs={0} sm={0} md={24} lg={24}>
                <br />
              </Col>
            </Row>

            <RoadmapSteps />
          </div>
        </Col>
      </Row>
    </StyledDivWrapper>
  );
}

export default RoadMapToSuccessComponent;
