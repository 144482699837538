import React, { useRef } from 'react';
import styled from 'styled-components';

import { Row, Col } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Player, ControlBar } from 'video-react';
import 'video-react/dist/video-react.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const StyledDivWrapper = styled.div`
  background-color: #002952;
  overflow: hidden;
  color: ${props => props.theme.whitePalette.original};
  min-height: 75vh;
  display: flex;
  @media only screen and (max-width: 576px) {
    padding-bottom: 20px;
  }
  h2 {
    color: ${props => props.theme.primeYellowPalette.original};
  }

  h4 {
    color: ${props => props.theme.whitePalette.original};
  }

  .check-icon-wrapper {
    position: relative;
    width: 50px;
    height: 50px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    > svg {
      margin-top: 3px;
    }

    ::after {
      background-color: rgba(27, 166, 255, 0.2);
      border-radius: 6px;
      height: 50px;
      width: 50px;
      display: inline-flex;
      content: '';
      position: absolute;
      transform: rotate(45deg);
    }
  }

  .with-us-wrapper {
    display: flex;
    > div:first-child {
      padding: 0 20px;
      font-size: 1.5em;
      color: ${props => props.theme.primeYellowPalette.original};
      @media only screen and (min-width: 767px) and (max-width: 1024px) {
        display: none;
      }
    }
    p {
      margin-bottom: 0px;
    }
  }

  .video-react .video-react-big-play-button {
    top: calc(50% - 13.031px);
    left: calc(50% - 56.031px);
  }
`;

function WhyWorkWithUsComponent() {
  const videoPlayerRef = useRef(null);

  return (
    <StyledDivWrapper>
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{ padding: '20px auto' }}
      >
        <Col xs={24} sm={24} md={24} lg={22} xl={19} xxl={16}>
          <Row
            type="flex"
            gutter={[
              { xs: 30, sm: 30, md: 20, lg: 30, xl: 0 },
              { xs: 30, sm: 30, md: 20, lg: 30, xl: 0 },
            ]}
            align="middle"
            justify="center"
          >
            <Col
              xs={{ span: 21, order: 2 }}
              sm={{ span: 21, order: 2 }}
              md={{ span: 20, order: 2 }}
              lg={{ span: 12, order: 1 }}
              xl={{ span: 12, order: 1 }}
              xxl={{ span: 12, order: 1 }}
            >
              <h2>WHY WORK WITH US</h2>
              <p>
                Working with PRIME will give you a first-hand experience and
                introduce you to the exciting landscape of the real estate
                industry. With a positive company culture, opportunities for
                personal and career growth, and the chance to work with
                experienced and topnotch professionals, your career here at
                PRIME is promising.
              </p>
            </Col>
            <Col
              xs={{ span: 24, order: 1 }}
              sm={{ span: 24, order: 1 }}
              md={{ span: 20, order: 1 }}
              lg={{ span: 12, order: 2 }}
              xl={{ span: 12, order: 2 }}
              xxl={{ span: 12, order: 2 }}
            >
              <div style={{ marginTop: 10 }}>
                <Player
                  poster="../../images/careers/recruitment-video-poster.jpg"
                  ref={videoPlayerRef}
                >
                  <source src="../../videos/recruitment-video.webm" />
                  <ControlBar />
                </Player>
              </div>
            </Col>
          </Row>

          <br />

          <Row type="flex" gutter={[10, 20]}>
            <Col
              xs={{ span: 20, offset: 1 }}
              sm={{ span: 20, offset: 1 }}
              md={{ span: 6, offset: 2 }}
              lg={{ span: 8, offset: 0 }}
              xl={{ span: 8, offset: 0 }}
              xxl={{ span: 8, offset: 0 }}
            >
              <div className="with-us-wrapper">
                <div>
                  <span className="check-icon-wrapper">
                    <FontAwesomeIcon fixedWidth icon="check" />
                  </span>
                </div>
                <div>
                  <h4>TEAM WITH US</h4>
                  <p>
                    We create a work environment where camaraderie is upheld.
                    The company culture is family-oriented, establishing an
                    optimistic atmosphere that is evident in the daily hustle
                    and bustle in the office.
                  </p>
                </div>
              </div>
            </Col>

            <Col
              xs={{ span: 20, offset: 1 }}
              sm={{ span: 20, offset: 1 }}
              md={{ span: 6, offset: 1 }}
              lg={{ span: 8, offset: 0 }}
              xl={{ span: 8, offset: 0 }}
              xxl={{ span: 8, offset: 0 }}
            >
              <div className="with-us-wrapper">
                <div>
                  <span className="check-icon-wrapper">
                    <FontAwesomeIcon fixedWidth icon="check" />
                  </span>
                </div>
                <div>
                  <h4>GROW WITH US</h4>
                  <p>
                    PRIME is a breeding ground for experts in the fields of
                    marketing, research, business development, information
                    technology, and organizational development.
                  </p>
                </div>
              </div>
            </Col>

            <Col
              xs={{ span: 20, offset: 1 }}
              sm={{ span: 20, offset: 1 }}
              md={{ span: 6, offset: 1 }}
              lg={{ span: 8, offset: 0 }}
              xl={{ span: 8, offset: 0 }}
              xxl={{ span: 8, offset: 0 }}
            >
              <div className="with-us-wrapper">
                <div>
                  <span className="check-icon-wrapper">
                    <FontAwesomeIcon fixedWidth icon="check" />
                  </span>
                </div>
                <div>
                  <h4>SUCCEED WITH US</h4>
                  <p>
                    Real estate is among the largest industries in the
                    Philippines. By joining the fastest growing real estate
                    service firm in the country, you become part of the booming
                    and evolving industry.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <br />
        </Col>
      </Row>
    </StyledDivWrapper>
  );
}

export default WhyWorkWithUsComponent;
